<template>
  <div class="w-full">
    <div class="flex flex-col justify-center w-full items-center text-xs">
      <div class="flex justify-center w-full items-center text-xs pt-3 pb-5">
        <div class="relative w-full text-center">
          <span class="absolute w-[calc(50%-16px)] h-px left-0 top-1/2 bg-[#5F677C]"/>
          <span class="text-slate-light font-bold">OR</span>
          <span class="absolute w-[calc(50%-16px)] h-px right-0 top-1/2 bg-[#5F677C]"/>
        </div>
      </div>
      <div class="flex flex-col w-full">
        <ButtonButton
          v-for="(data, key) in webData"
          :key="key"
          :is-loading="data.loading"
          :disabled="isLoading"
          :theme="data.theme"
          class="mb-2"
          size="sm"
          @click.prevent="emits('onSocialLogin', data.label)"
        >
          <div class="flex items-center justify-center">
            <img class="h-5 w-5 mr-2" :src="data.icon" :alt="data.label">
            <span>{{ data.buttonText }}</span>
          </div>
        </ButtonButton>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  webData: {
    type: Object,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['onSocialLogin',]);
</script>
