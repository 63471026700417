<template>
  <ModalMainNew modal-width-mode="sm" @close="close(true)">
    <div class="px-1 pt-2 w-full">
      <article class="animate-fade-in">
        <div class="flex flex-col items-center pb-3">
          <div v-if="showWallet" class="max-w-[15rem]">
            <h1 class="tracking-wide font-semibold text-center animate-slide-down-fade-in01s">
              {{ title }}
            </h1>
            <div
              v-if="connectMessage || showWallet"
              class="leading-tight animate-slide-down-fade-in04s text-center mt-1"
            >
              <p class="text-sm text-[#B1BAD3]">
                {{ message }}
              </p>
            </div>
          </div>
          <div v-if="!isUserLogged || userHasAddress || connectFromEmail" class="w-full">
            <ModalConnectWallet
              v-if="showWallet && !emailSent"
              :web-data="web3Providers"
              :is-loading="isLoading"
              :hide-connect="web3Providers.length === 1"
              :web3-only="connectWeb3Only"
              @on-login="login"
            />
            <template v-if="!connectWeb3Only">
              <ModalConnectEmail v-if="!emailSent" @on-email-submit="emailLogin"/>
              <div v-else-if="emailSent">
                <p class="text-center leading-tight text-gray-300 text-md mt-2 mb-6 pt-2 animate-slide-down-fade-in01s">
                  We've emailed a one-time link to <b>{{ email }}</b>. Click the link to sign in or register an acccount.
                </p>
                <ButtonButton
                  :is-loading="loading"
                  :disabled="resendCooldown"
                  class="w-full"
                  size="sm"
                  @click.prevent="resendEmail"
                >
                  {{
                    resendCooldown
                      ? `Resend email in ${cooldownTimeRemaining}s`
                      : "Resend Email Verification"
                  }}
                </ButtonButton>
              </div>
            </template>
            <ModalConnectSocial
              v-if="!connectWeb3Only && !emailSent && web2Providers.length"
              :web-data="web2Providers"
              :is-loading="isLoading"
              @on-social-login="login"
            />
          </div>
          <div v-else class="pt-6 pb-4">
            <ButtonButton @click.prevent.stop="openAddAliasModal('EthereumAddress')">
              Connect
            </ButtonButton>
          </div>
          <h2
            v-if="errorMessage"
            class="w-full inline-block text-red text-sm mt-2 text-center"
          >
            {{ errorMessage }}
          </h2>
        </div>
      </article>
    </div>
  </ModalMainNew>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useIntervalFn } from '@vueuse/core';

import { useUiStore } from '@/store/ui';
import { useAuthStore } from '@/store/auth';
import { useUserStore } from '@/store/user';
import { useWalletProviderStore } from '@/store/wallet-provider';
import { getConfigSplit } from '@/utils/getConfig';

import googleLogo from '~/assets/img/logos/google.svg';
import facebookLogo from '~/assets/img/logos/facebook.svg';

const { $gtmCustomEvent, } = useNuxtApp();

const authStore = useAuthStore();
const {
  signaturePending,
  errorMessage,
  attach,
  isUserLogged,
  address,
  attachAddress,
} = storeToRefs(authStore);
const {
  loginWallet,
  attachWallet,
  loginEmailOneTimeLink,
  loginWithSocial,
} = authStore;

const uiStore = useUiStore();
const {
  showInfoModal,
  connectNoLogin,
  connectFromEmail,
  connectWeb3Only,
  connectMessage,
  connectSwap,
  showWalletModal,
  connectChainType,
  web3LoginProvider,
} = storeToRefs(uiStore);
const { closeConnectModal, openAddAliasModal, } = uiStore;

const userStore = useUserStore();
const { userData, } = storeToRefs(userStore);

const walletProviderStore = useWalletProviderStore();
const { disconnect, } = walletProviderStore;

const configProviders = getConfigSplit('AUTH_PROVIDERS');

const selected = ref();
const showWallet = ref(true);
const emailSent = ref(false);
const loading = ref(false);
const email = ref('');
const cooldownDuration = ref(15);
const resendCooldown = ref(false);
const cooldownTimeRemaining = ref(15);
const cooldownInterval = ref();
const providers = ref([
  {
    label: 'MetaMask',
    loading: false,
    icon: 'metamaskNoBg',
    type: 'Web3',
    isLarge: true,
    chain: 'Ethereum',
  },
  {
    label: 'CoinBase',
    loading: false,
    icon: 'coinbaseNoBg',
    type: 'Web3',
    isLarge: false,
    chain: 'Ethereum',
  },
  {
    label: 'WalletConnect',
    loading: false,
    icon: 'walletconnectNoBg',
    type: 'Web3',
    isLarge: false,
    chain: 'Ethereum',
  },
  {
    label: 'Phantom',
    loading: false,
    icon: 'phantomNoBg',
    type: 'Web3',
    isLarge: false,
    chain: 'Solana',
  },
  {
    label: 'Google',
    loading: false,
    isLarge: false,
    icon: googleLogo,
    theme: 'google',
    buttonText: 'Continue with Google',
    type: 'Web2',
    chain: null,
  },
  // TODO: 30th July 2024 @hipkiss Add back in when facebook has been added as an auth option in the backend.
  // {
  //   label: 'Facebook',
  //   loading: false,
  //   isLarge: false,
  //   icon: facebookLogo,
  //   theme: 'facebook',
  //   buttonText: 'Continue with Facebook',
  //   type: 'Web2',
  //   chain: null,
  // },
]);

const isLoading = computed(() => !!providers.value.some(v => v.loading));
const userHasAddress = computed(() => !!userData.value?.aliases?.EthereumAddress || !!userData.value?.aliases?.SolanaAddress);
const userAlias = computed(() => userData.value?.aliases?.EthereumAddress || userData.value?.aliases?.SolanaAddress);
const title = computed(() => emailSent.value ? 'Sign In' : 'Connect your Web3 wallet');
const message = computed(() => emailSent.value ? 'Check your email' : connectMessage.value);
const activeProvider = computed(() => {
  return providers.value.filter(provider => configProviders.includes(provider.label.toLowerCase()));
});
const web3Providers = computed(() => {
  return providers.value
  .filter((v) => {
    return userData.value?.aliases ? (!!userData.value?.aliases?.SolanaAddress && v.chain === 'Solana') || (!!userData.value?.aliases?.EthereumAddress && v.chain === 'Ethereum') : true;
  })
  .filter(v => v.type === 'Web3')
  .filter(v => !connectChainType.value || v.chain === connectChainType.value);
});
const web2Providers = computed(() => providers.value.filter(v => v.type === 'Web2'));

errorMessage.value = '';
showInfoModal.value = false;
$gtmCustomEvent({
  event: 'modal_show',
  action: 'modalOpen',
  modalName: 'Connect',
});

function startCooldown() {
  if (cooldownInterval.value) {
    return;
  }

  resendCooldown.value = true;
  cooldownTimeRemaining.value = cooldownDuration.value;

  const { pause, resume, } = useIntervalFn(
    () => {
      if (cooldownTimeRemaining.value > 1) {
        cooldownTimeRemaining.value -= 1;
      } else {
        resendCooldown.value = false;
        cooldownTimeRemaining.value = cooldownDuration.value;
        pause(); // Stop the interval
        cooldownInterval.value = null; // Clear the interval reference
      }
    },
    1000,
    { immediate: true, }
  );

  cooldownInterval.value = { pause, resume, };
}

async function resendEmail() {
  try {
    loading.value = true;
    await loginEmailOneTimeLink(email.value?.toLowerCase()?.trim());
    startCooldown();
  } catch (err) {
    cooldownTimeRemaining.value = cooldownDuration.value;
    if (err?.status === 429) {
      errorMessage.value = 'Too many requests. Please try again later.';
      startCooldown();
    } else {
      errorMessage.value = err;
      resendCooldown.value = false;
    }
  } finally {
    loading.value = false;
  }
}

async function login(provider) {
  providers.value.forEach((prov) => {
    prov.loading = prov.label === provider;
  });
  selected.value = providers.value.find(v => v.label === provider);
  errorMessage.value = '';
  attach.value = !!address.value || !!attachAddress.value;

  if (attach.value) {
    await attachWallet(provider, connectNoLogin.value);
  } else if (selected.value.type === 'Web2') {
    await loginWithSocial(provider);
    providers.value.forEach((prov) => {
      prov.loading = false;
    });
  } else {
    try {
      web3LoginProvider.value = provider;
      await loginWallet(provider, connectNoLogin.value, true);
    } catch (error) {
      errorMessage.value = `Unable to connect to ${selected.value.label} wallet.`;
      providers.value.forEach((prov) => {
        prov.loading = false;
      });
      return;
    }
  }

  if (userData.value?.aliases && (userAlias.value?.toLowerCase() !== address.value?.toLowerCase())) {
    errorMessage.value = `Incorrect wallet address selected in ${selected.value.label}.`;
    return;
  }

  if (connectSwap.value) {
    showWalletModal.value = true;
  }

  if (connectFromEmail.value) {
    const aliasType = selected.value.chain === 'Solana' ? 'SolanaAddress' : 'EthereumAddress';
    openAddAliasModal(aliasType, 2, connectSwap.value ? 'swap' : null);
    return;
  }

  if (connectNoLogin.value) {
    close();
  }
}

function emailLogin(address) {
  emailSent.value = true;
  email.value = address;
  startCooldown();
}

function close(onDisconnect) {
  errorMessage.value = '';
  signaturePending.value = false;
  closeConnectModal();
  attach.value = false;

  if (onDisconnect) {
    disconnect();
  }
}

watch(
  () => isUserLogged.value,
  (newVal) => {
    if (newVal && !connectNoLogin.value) {
      close();
    }
  }
);

watch(
  () => errorMessage.value,
  (newVal) => {
    if (newVal && selected.value) {
      providers.value.forEach((prov) => {
        prov.loading = false;
      });
    }
  }
);
</script>
